<template>
    <div>
        <Navigation />
        <!-- 广告语 -->
        <div class="bg_box">
            <div class="noneBox"></div>
            <div class="box">
                <p class="back" @click="$router.push('/wordNav')">返回</p>

                <div class="ct_box flex">
                    <div class="form_upload_box">
                        <ai_title ai_title="“一言一文”AI广告语" />
                        <div class="form_ct flex">
                            <p class="upLoad_text">主题描述：</p>
                            <div class="upload_box">
                                <el-input v-model="form.keyword" placeholder="如：产品名称、产品描述等"></el-input>
                            </div>
                        </div>
                        <ai_button_file_data ref="ai_button_file_data" @file_data="file_data" />
                        <ai_text_content :contentData="contentData" />
                    </div>
                    <wordNav />
                    <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                        @close="aiNumShow = false" @postData="postData" />
                    <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false" />
                </div>
            </div>
        </div>
        <footerBot />

    </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_title from '@/components/ai_title.vue'
import ai_text_content from '@/components/ai_text_content.vue'
import ai_button_file_data from '@/components/ai_button_file_data.vue'
export default {
    name: '',
    created() { },
    mounted() {
        this.getUserInfo()
    },
    components: {
        wordNav,
        ai_title,
        ai_text_content,
        ai_button_file_data
    },
    data() {
        return {
            form: {
                keyword: '', // 关键词
            },
            selectList: ['广告语',],
            contentData: {}, //内容
            aiNumShow: false,
            aiPoupShow: false,
        }
    },
    methods: {
        // 获取用户信息
        getUserInfo() {
            let $user_info = JSON.parse(localStorage.getItem('user_info'))
            if ($user_info) {
                this.curlGet('/api/user/info').then((res) => {
                    if (res.data.code) {
                        this.$user_info = res.data.data
                        this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
                    }
                })
            }
        },
        file_data() {
            // if (this.form.type == '') {
            //     return this.$message({
            //         message: '请输入从事行业',
            //         type: 'warning',
            //         offset: 80
            //     });
            // }

            // if (this.form.keyword == '') {
            //     return this.$message({
            //         message: '请输入关键字',
            //         type: 'warning',
            //         offset: 80
            //     });
            // }
            if (this.$user_info.money <= 0) {
                this.aiPoupShow = true
            } else {
                this.aiNumShow = true
            }
        },
        postData() {
            this.aiNumShow = false
            this.$refs.ai_button_file_data.loading_show()
            this.curlPost('/file/advertisement_create', {
                // type: this.form.type,
                keyword: this.form.keyword,
                mobile: this.$user_info.mobile,
            }).then(res => {
                this.$refs.ai_button_file_data.loading_show()
                if (res.data.code) {
                    this.contentData = res.data.data
                    // 扣费
                    this.curlPost('/api/users/money_log/spend', {
                        ai_id: res.data.data.id,
                        amount: res.data.data.money,
                    }).then(res => {
                        if (res.data.code) {
                            this.getUserInfo()
                        }
                    })
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        }
    },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai.scss'
</style>